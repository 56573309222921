<template>
  <div class="staking_box">
    <div class="card">
      <div class="haeder_box">Reward Status</div>
      <div class="syp_row">
        <label class="syp_label text_upper">Recipient</label>
        <div class="syp_val_box">
          <div class="tag_list">
            <span
              class="tag_item"
              @click="getVestingAmount()"
              v-loading="getLoading"
            >
              {{ rewardName }}
            </span>
          </div>
        </div>
      </div>
      <div class="syp_row">
        <label class="syp_label text_upper">Available</label>
        <div class="syp_val_box">
          <div class="val_box">
            <span class="val">{{ available }}</span>
            <img class="val_img" src="../assets/img/logo_nav.png" alt />
          </div>
        </div>
      </div>
      <div class="syp_row">
        <label class="syp_label text_upper">More Info</label>
        <div class="describe">
          The receipient has {{ lockAmount.toFixed(2) }}
          <img class="icon_img" src="../assets/img/logo_nav.png" alt />
          locked until {{ lockDate }}.
        </div>
      </div>
      <div class="btn_box">
        <div
          class="btn_dialog"
          style="margin-top: 10px"
          @click="actionClaim"
          v-loading="claimLoading"
        >
          Claim Now
        </div>
      </div>
      <!-- <ul class="content_list">
        <li>
          <span>Destination</span>
          <span style="font-size:10px">{{destination}}</span>
        </li>
        <li>
          <span class="">Available</span>
                      <span class="">{{available}} SYP</span>
                    </li>
                            <li>
                      <span class="">Available Date</span>
                      <span class="">{{date}}</span>
                    </li>
                  </ul>-->
    </div>
  </div>
</template>

<script>
import { Connection, Transaction, PublicKey } from "@solana/web3.js";
import {
  getTokenAccounts,
  createTokenAccount,
  getSignatureStatus,
} from "../assets/js/getBalance";
import { SapInstruction } from "../assets/js/sapInstruction";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { rpcConfig, rpcUrl } from "../assets/js";
import { sleep } from "../assets/js/vesting/utils";

export default {
  data() {
    return {
      destination: "",
      available: 0,
      vestId: 0,
      date: "",
      seed: "",
      claimLoading: false,
      connection: new Connection(rpcUrl, rpcConfig),
      rewardName: "Click to find reward",
      getLoading: false,
      lockAmount: 0,
      lockDate: "later dates",
    };
  },
  async mounted() {
    if (this.$store.state.walletConnectInfo) {
      this.getVestingAmount();
    } else {
      console.error("has not connect to wallet");
      return;
    }
  },
  methods: {
    async getVestingAmount() {
      // this.connection = this.$store.state.walletConnectInfo.connection;
      // this.connection = new Connection(MainUrl);
      this.getLoading = true;
      const wallet = this.$store.state.walletConnectInfo.wallet;
      const walletKey = wallet.publicKey.toBase58();
      const res = await this.$axios.post("/getMyVest", { pubkey: walletKey });
      if (res.data.code) {
        let temp = res.data.data;
        if (temp.claimed == 0) {
          let nowDate = new Date();
          let unlockDate = new Date(temp.unlockDate);
          if (nowDate > unlockDate) {
            this.rewardName = temp.name;
            this.vestId = temp._id;
            this.available = parseFloat(temp.amount);
          } else {
            this.rewardName = "No reward";
            this.vestId = "";
            this.available = 0;
            this.lockAmount = parseFloat(temp.amount);
            this.lockDate = `${unlockDate.getFullYear()}-${
              unlockDate.getMonth() + 1
            }-${unlockDate.getDate()}`;
          }
        }
      } else {
        this.rewardName = "No reward";
      }
      this.getLoading = false;
    },
    async goClaim() {
      if (this.available > 0) {
        const ownerKey = "AydJEQNvH44P1VA8uSMqfF6MvqMVoeLoxMzt8Wtfrr3B";
        const sourceKey = "FpC29f4hXQYQzFumDAMkFqGG4Hes75p5Qfor3Cy9nLNh";
        const sypMintKeyMain = "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ";
        // const sypMintKeyDev = 'CPoQKy6pW76xhozbtYiMaCpejvThWjuwWTQpR2UCxrif';
        let wallet = this.$store.state.walletConnectInfo.wallet;
        let walletKey = wallet.publicKey.toBase58();
        let userSypAcc = await getTokenAccounts(
          this.connection,
          new PublicKey(walletKey),
          new PublicKey(sypMintKeyMain)
        );
        if (!userSypAcc) {
          console.log("user has no syp account");
          this.$notify({
            title: "Warning",
            message: "Please create SYP account firsrt.",
            type: "warning",
          });
          let temp = await createTokenAccount(
            this.connection,
            wallet,
            sypMintKeyMain
          );
          if (temp.code == 1) {
            while (!userSypAcc) {
              await sleep(3000);
              userSypAcc = await getTokenAccounts(
                this.connection,
                new PublicKey(walletKey),
                new PublicKey(sypMintKeyMain)
              );
            }
            this.notifyWithSignature(
              "Create SYP account success. Please sign claim.",
              temp.signatrue
            );
          } else {
            this.$notify({
              title: "Error",
              message: "Create SYP account error",
              type: "error",
            });
            return;
          }
        }
        if (userSypAcc) {
          let trxi = SapInstruction.createTransferInstruction(
            TOKEN_PROGRAM_ID,
            new PublicKey(sourceKey),
            userSypAcc,
            new PublicKey(ownerKey),
            [],
            this.available * 10 ** 9
          );
          let transaction = new Transaction();
          transaction.add(trxi);
          let { blockhash } = await this.connection.getLatestBlockhash();
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = new PublicKey(walletKey);
          try {
            transaction = await wallet.signTransaction(transaction);
          } catch (err) {
            this.$notify({
              title: "Error",
              message: "transaction cancelled",
              type: "error",
            });
            console.error("cliam sign cancelled", err);
            return;
          }
          // return;
          const res = await this.$axios.post("/claimVestTokens", {
            data: transaction.serialize({ requireAllSignatures: false }),
            amount: this.available * 10 ** 9,
            pubkey: walletKey,
            tokenPubkey: userSypAcc.toBase58(),
          });
          if (res.data.code) {
            this.notifyWithSignature("Claim Reward Success", res.data.data);
          } else {
            this.$notify({
              title: "Error",
              message: "Claim Reward Fail",
              type: "error",
            });
          }
        }
      }
    },
    async goClaim2() {
      if (this.vestId && this.available > 0) {
        // use wallet
        let wallet = this.$store.state.walletConnectInfo.wallet;
        let walletKey = wallet.publicKey.toBase58();
        // use user syp account
        const sypMintKeyMain = "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ";
        let userSypAcc = await getTokenAccounts(
          this.connection,
          new PublicKey(walletKey),
          new PublicKey(sypMintKeyMain)
        );
        if (!userSypAcc) {
          console.log("user has no syp account");
          this.$notify({
            title: "Warning",
            message: "Please create SYP account firsrt.",
            type: "warning",
          });
          let temp = await createTokenAccount(
            this.connection,
            wallet,
            sypMintKeyMain
          );
          if (temp.code == 1) {
            while (!userSypAcc) {
              await sleep(3000);
              userSypAcc = await getTokenAccounts(
                this.connection,
                new PublicKey(walletKey),
                new PublicKey(sypMintKeyMain)
              );
            }
            this.notifyWithSignature(
              "Create SYP account success. Please sign claim.",
              temp.signatrue
            );
          } else {
            this.$notify({
              title: "Error",
              message: "Create SYP account error",
              type: "error",
            });
            return;
          }
        }
        if (!userSypAcc) return;
        const {
          data: { data },
        } = await this.$axios.post("/claimVest1a", {
          _id: this.vestId,
          dest: userSypAcc.toBase58(),
          blockhash: await this.connection.getLatestBlockhash(),
        });
        let tx = Transaction.from(Buffer.from(data, "base64"));
        tx = await wallet.signTransaction(tx);
        const signature = await this.connection.sendRawTransaction(
          tx.serialize()
        );
        if (signature) {
          this.$axios.post("/claimVest1b", {
            _id: this.vestId,
            signature,
          });
          this.notifyWithSignature("Claim Reward Success", signature);
        } else {
          this.$notify({
            title: "Error",
            message: "Claim Reward Fail",
            type: "error",
          });
        }
      }
    },
    async actionClaim() {
      if (this.claimLoading == false) {
        this.claimLoading = true;
        await this.goClaim2();
        this.claimLoading = false;
      }
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    formatDate2(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        // y +
        // "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) //+
        // ":" +
        // this.add0(s)
      );
    },
    async notifyWithSignature(msg, signature) {
      let temp = await getSignatureStatus(this.connection, signature);
      const h = this.$createElement;
      this.$notify({
        title: "Success",
        dangerouslyUseHTMLString: true,
        message: h("div", {}, [
          h("p", {}, msg),
          h("p", {}, [
            h(
              "a",
              {
                attrs: {
                  href: "https://explorer.solana.com/tx/" + signature,
                  target: "_blank",
                },
              },
              "view on explore"
            ),
          ]),
        ]),
        type: "success",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  padding: 40px 30px;
  width: 400px;
  margin: 100px auto 100px;
  border: 1px solid #4285c4;
  border-radius: 10px;
  box-shadow: 0px 0px 18px 8px #4285c4;
  min-height: 532px;
}

.text_upper {
  text-transform: uppercase;
}

.haeder_box {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;

  & > img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}

.syp_row {
  margin-top: 25px;

  .syp_label {
    color: #0c71c3;
    font-size: 14px;
  }

  .syp_val_box {
    margin-top: 10px;
    padding: 0px 0 20px;
    border-bottom: 1px solid #777;

    .tag_list {
      .tag_item {
        font-size: 14px;
        color: #fdfdfd;
        padding: 5px 10px;
        border-radius: 40px;
        background-color: #9c9c9c;
        margin-right: 20px;
        margin-bottom: 10px;
        display: inline-block;
        cursor: pointer;
      }
    }

    .val_box {
      display: flex;
      align-items: center;

      .val {
        font-size: 32px;
        font-weight: bold;
      }

      .val_img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid #671f1f;
        padding: 5px;
        box-sizing: content-box;
        margin-left: 20px;
      }
    }
  }

  .describe {
    margin-top: 10px;
    font-size: 14px;
    color: #999;

    .icon_img {
      width: 14px;
      height: 14px;
      filter: grayscale(100%);
    }
  }
}

.btn_box {
  text-align: center;
  margin: 30px 0;

  .btn_dialog {
    font-size: 16px;
    background-color: #0c71c3;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    color: #fff;

    margin: 0 auto;

    cursor: pointer;
  }
}
</style>
